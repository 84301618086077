import { FormGroup } from '../FormGroup';
import { StyledInputGroup } from './styled';
import { useAddDocument } from 'src/pages/AddDocumentPage/context/add-document-form-context';
import { documentOwnerNameMap } from 'src/constants';
import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { useEmployeeData } from '../../hooks/useEmployeeData';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

export const OwnerForm = () => {
  const { setField, errors, document } = useAddDocument();
  const { data, search } = useEmployeeData();
  const [searchParams] = useSearchParams();

  const params = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );

  useEffect(() => {
    if (params.employeeId) {
      setField('employeeId', params.employeeId);
    }
  }, [params]);

  const defaultValue = useMemo(
    () =>
      !!params.employeeId ? { defaultValue: { value: params.employeeId } } : {},
    [params.employeeId],
  );

  const isEmployeeSelected = useMemo(
    () => !!params.employeeId,
    [params.employeeId],
  );

  return (
    <FormGroup
      title="Titular do documento"
      description="Indique a qual pessoa este documento está associado e se ele
    pertence ao próprio pessoa ou ao seu dependente."
      required
      showRequiredLabel
    >
      <StyledInputGroup>
        <SelectField
          label="Pessoa"
          focused={isEmployeeSelected}
          disabled={isEmployeeSelected}
          error={!!errors?.employeeId}
          onChange={(e) => search(e.target.value)}
          searchable
          fullWidth
          helperText={
            errors?.employeeId || 'Qual pessoa este documento está associado.'
          }
          onSelectChange={(_, e) => setField('employeeId', e?.value)}
          options={data?.map((employee) => ({
            label: employee.name,
            value: employee.id,
          }))}
          {...defaultValue}
        />
        <SelectField
          label="Titular do documento"
          fullWidth
          onSelectChange={(_, e) => setField('documentOwner', e?.value)}
          error={!!errors?.documentOwner}
          helperText={
            errors?.documentOwner ||
            'Quem é o titular do documento que está sendo carregado.'
          }
          options={Object.entries(documentOwnerNameMap).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
        />
      </StyledInputGroup>
    </FormGroup>
  );
};
