import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { trpc } from 'src/api/client';
import { SEARCH_EMPLOYEE_LIMIT, SEARCH_INPUT_DELAY } from 'src/constants';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { useAddDocument } from 'src/pages/AddDocumentPage/context/add-document-form-context';

export const useEmployeeData = () => {
  const { selectedCompany } = useSelectedCompany();
  const [search, setSearchParam] = useState('');
  const query = useDebouncedValue(search, SEARCH_INPUT_DELAY);
  const { setField } = useAddDocument();
  const [searchParams] = useSearchParams();
  const params = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams],
  );

  const {
    data: employeesList,
    refetch: fetchEmployees,
    isLoading: isEmployeesLoading,
  } = trpc.findEmployeesBasicInfo.useQuery(
    { companyId: selectedCompany.id, query, limit: SEARCH_EMPLOYEE_LIMIT },
    { enabled: false, refetchOnWindowFocus: false },
  );

  const {
    data: employeeDetails,
    refetch: fetchEmployeeById,
    isLoading: isEmployeeLoading,
  } = trpc.findEmployeeById.useQuery(
    {
      companyId: selectedCompany.id,
      employeeId: params.employeeId,
    },
    { enabled: false, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (params.employeeId) {
      setField('employeeId', params.employeeId);
      fetchEmployeeById();
    } else {
      fetchEmployees();
    }
  }, [params.employeeId, query, selectedCompany]);

  const data = useMemo(() => {
    if (params.employeeId) {
      return employeeDetails ? [employeeDetails] : [];
    }

    return employeesList?.employees;
  }, [employeeDetails, employeesList, params.employeeId]);

  return {
    data,
    fetch: !!params.employeeId ? fetchEmployeeById : fetchEmployees,
    loading: isEmployeesLoading || isEmployeeLoading,
    search: setSearchParam,
  };
};
