import { OwnerForm } from './components/OwnerForm';
import { SignatureForm } from './components/SignatureForm';
import { DocumentForm } from './components/DocumentForm';
import { AdditionalInformationForm } from './components/AdditionalinformationForm';
import { useIsAdmin } from 'src/hooks/useIsAdmin';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const Form = () => {
  const isAdmin = useIsAdmin();
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();

  const prevSelectedCompany = useRef<string | null>(
    selectedCompany?.id || null,
  );

  useEffect(() => {
    if (!selectedCompany?.id) return;

    if (
      prevSelectedCompany.current &&
      prevSelectedCompany.current !== selectedCompany.id
    ) {
      navigate('/document-management');
    }

    prevSelectedCompany.current = selectedCompany.id;
  }, [selectedCompany?.id]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        maxWidth: 'calc(100% - 138px)',
      }}
    >
      <OwnerForm />
      <DocumentForm />
      {isAdmin && <SignatureForm />}
      <AdditionalInformationForm />
    </div>
  );
};
