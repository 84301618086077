import { OwnerForm } from './components/OwnerForm';
import { SignatureForm } from './components/SignatureForm';
import { DocumentForm } from './components/DocumentForm';
import { AdditionalInformationForm } from './components/AdditionalinformationForm';
import { useIsAdmin } from 'src/hooks/useIsAdmin';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useAddDocument } from '../../context/add-document-form-context';
import { useEffect } from 'react';

export const Form = () => {
  const isAdmin = useIsAdmin();
  const { selectedCompany } = useSelectedCompany();
  const { resetForm } = useAddDocument();

  useEffect(() => {
    resetForm();
  }, [selectedCompany]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        maxWidth: 'calc(100% - 138px)',
      }}
    >
      <OwnerForm />
      <DocumentForm />
      {isAdmin && <SignatureForm />}
      <AdditionalInformationForm />
    </div>
  );
};
